import React, { useState } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { FiXCircle } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import HubspotForm from '../hubspot/HubspotForm';

// @ todo popup form
function ReferFriendWidget({ className, knowledgeBase }) {
	const { image, kbImage } = useStaticQuery(graphql`
		{
			image: file(relativePath: { eq: "blog/refer-a-friend-default.png" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						width: 300
						placeholder: TRACED_SVG
						layout: FIXED
					)
				}
			}
			kbImage: file(relativePath: { eq: "kb/refer-a-friend-default.png" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: TRACED_SVG
						layout: FULL_WIDTH
					)
				}
			}
		}
	`);
	const [showDialog, setShowDialog] = useState(false);

	return (
		<div className={className ? className : ''}>
			<a href="#" onClick={() => setShowDialog(true)}>
				{knowledgeBase && (
					<GatsbyImage
						image={kbImage.childImageSharp.gatsbyImageData}
						alt="Refer a Friend"
						className="h-auto m-auto left-0 top-0 cursor-pointer"
						style={{ maxWidth: 900 }}
					/>
				)}
				{!knowledgeBase && (
					<GatsbyImage
						image={image.childImageSharp.gatsbyImageData}
						alt="Refer a Friend"
						className="h-auto m-auto left-0 top-0 cursor-pointer"
					/>
				)}
			</a>
			<DialogOverlay
				className="z-40"
				style={{ background: 'hsla(0, 100%, 0%, 0.75)' }}
				isOpen={showDialog}
				onDismiss={() => setShowDialog(false)}
			>
				<DialogContent
					className="shadow rounded relative max-w-2xl"
					style={{
						width: `100%`,
					}}
					aria-label="modal"
				>
					<button
						className="absolute focus:outline-none"
						onClick={() => setShowDialog(false)}
						aria-label="close"
						style={{ top: `1rem`, right: `1rem` }}
					>
						<FiXCircle />
					</button>
					<HubspotForm
						cssClass="lw-modal-hubspot-form"
						standardFormID="edbe3300-fea2-4cfc-9943-4ca99ae9149d"
					/>
				</DialogContent>
			</DialogOverlay>
		</div>
	);
}

ReferFriendWidget.propTypes = {
	className: PropTypes.string,
	knowledgeBase: PropTypes.bool,
};

export default ReferFriendWidget;
