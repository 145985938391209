import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import dayjs from 'dayjs'

import swapCmsUrlWithCdn from '../utils/swapCmsUrlWithCdn'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import StructuredData from '../components/structured-data'
import BlogSidebarAdWidget from '../components/blog-sidebar-ad-widget/blog-sidebar-ad-widget'
import SubscribeWidget from '../components/subscribe-widget/subscribe-widget'
import CategoryWidget from '../components/category-widget/category-widget'
import ReferFriendWidget from '../components/refer-friend-widget/refer-friend-widget'
import SearchForm from '../components/search/search-form'
import SubscribeHorizontalWidget from '../components/subscribe-widget/subscribe-horizontal-widget'
import ShareSocialIcons from '../components/kb/share-social-icons'
import useSiteMetadata from '../hooks/use-site-metadata'
import parseHtml from '../components/blocks/parser'

const PostTemplate: React.FC<PostTemplateProps> = ({
  data,
  location,
  author,
  date,
  modified,
}) => {
  const siteMetadata = useSiteMetadata()
  const { post } = data
  const postURL = `${siteMetadata.siteUrl}${location.pathname}`
  const { categories, tags } = post

  const dateFormat = dayjs(post.date).format('MMMM D, YYYY')
  const modifiedFormat = dayjs(post.modified).format('MMMM D, YYYY')

  const contentNode = post.lwContent.isGutenberg ? (
    <div className="entry blog-post">{parseHtml(post.lwContent.content)}</div>
  ) : (
    <div
      className="entry blog-post"
      dangerouslySetInnerHTML={{ __html: post.lwContent.content }}
    />
  )

  const featuredImage = swapCmsUrlWithCdn(post.featuredImage?.node?.mediaItemUrl);

  return (
    <Layout>
      <Seo post={post} />
      <StructuredData pageType="article" post={post} />
      <BreadcrumbStructuredData
        title={post.title}
        uri={post.uri}
        category={categories.nodes[0]}
      />
      <SearchForm />
      <div className="relative py-10 mb-10">
        <div className="contrast-overlay"></div>
        <div className="container relative z-20 text-white">
          <h1
            className="text-white mb-6"
            style={{ textShadow: `rgb(0 0 0 / 30%) 0px 2px 4px` }}
            dangerouslySetInnerHTML={{ __html: post.title }}
          />
          <div
            className="text-sm"
            style={{ textShadow: `0 0 2px rgb(51 32 32 / 75%)` }}
          >
            Posted on <time dateTime={post.date}>{dateFormat}</time> by{' '}
            {post.author?.node && (
              <Link
                to={post.author.node.uri}
                className="text-white hover:text-white underline"
              >
                {post.author.node.name}
              </Link>
            )}
            {post.modified && modifiedFormat !== dateFormat && (
              <>
                {' | Updated: '}
                <time dateTime={post.modified}>{modifiedFormat}</time>
              </>
            )}
            <div className="mt-2">
              <Link to="/" className="text-white hover:text-white underline">
                Home
              </Link>{' '}
              &gt;{' '}
              <Link
                to="/blog/"
                className="text-white hover:text-white underline"
              >
                Blog
              </Link>{' '}
              &gt;{' '}
              {categories.nodes[0] && (
                <Link
                  to={categories.nodes[0].uri}
                  className="text-white hover:text-white underline"
                >
                  {categories.nodes[0].name}
                </Link>
              )}{' '}
              &gt; {post.title}
            </div>
          </div>
        </div>
        {featuredImage && (
          <div
            className="absolute hidden md:block top-0 left-0 w-full h-full z-10"
            style={{ backgroundImage: `url(${featuredImage})`, backgroundSize: 'cover', backgroundPosition: '50% 50%' }}
          />
        )}
      </div>

      <div className="blog-post container grid pb-10 lg:grid-cols-12 gap-y-20 lg:gap-y-0 lg:gap-x-20">
        <div className="lg:col-span-7 xl:col-span-8">
          <ShareSocialIcons
            className="mb-5"
            postTitle={post.title}
            postURL={postURL}
          />
          {contentNode}
          {tags.nodes[0] && (
            <div className="pt-2 mb-6 text-sm text-gray-700">
              Tagged with:{' '}
              {tags.nodes.map((tag, i, array) => (
                <Link key={tag.name} to={tag.uri}>
                  {tag.name}
                  {i < array.length - 1 ? ', ' : null}
                </Link>
              ))}
            </div>
          )}
          <ShareSocialIcons
            className="my-5"
            postTitle={post.title}
            postURL={postURL}
          />
          {post.author?.node &&
            post.author.node.id !== 'dXNlcjo0NA==' && ( // if author is LW don't show author box
              <div className="bg-gray-100 p-8">
                <div className="flex mb-6">
                  {post.author.node.avatar.imageFile?.childImageSharp && (
                    <GatsbyImage
                      image={post.author.node.avatar.imageFile.childImageSharp?.gatsbyImageData}
                      alt={`Avatar for ${post.author.node.name}`}
                      className="rounded-full flex-shrink-0" />
                  )}
                  <div className="ml-4">
                    <div className="text-sm">About the Author</div>
                    <h2 className="text-2xl">{post.author.node.name}</h2>
                  </div>
                </div>
                <p
                  className="text-sm"
                  style={{ paddingBottom: `20px` }}
                  dangerouslySetInnerHTML={{
                    __html: post.author.node.description,
                  }}
                />
                <p>
                  {post.author && (
                    <Link
                      className="lw-footer-author-link"
                      to={post.author.node.uri}
                    >
                      View All Posts By {post.author.node.name}
                    </Link>
                  )}
                </p>
              </div>
            )}
        </div>
        <div className="lg:col-span-5 xl:col-span-4">
          <BlogSidebarAdWidget tags={tags.nodes} />
          <SubscribeWidget />
          <CategoryWidget className="mt-10" />
          <ReferFriendWidget className="my-10 text-center" />
        </div>
      </div>

      <SubscribeHorizontalWidget />
    </Layout>
  )
}

type PostTemplateProps = {
  data: {
    post: {
      title: string
      lwContent: {
        isGutenberg: boolean
        content?: string
      }
      date: string
      modified: string
      slug: string
      uri: string
      featuredImage: null | {
        node?: {
          mediaItemUrl: string;
          altText: string
          publicURL: string
          localFile: { childImageSharp: { fluid: FluidObject } }
        }
      }
      author: {
        node?: {
          name: string
          id: string
          description: string
          uri: string
          avatar: {
            url: string
            imageFile: { childImageSharp: { fixed: FixedObject } }
          }
        }
      }
      seo: any
      categories: {
        nodes?: Array<{
          name: string
          uri: string
        }>
      }
      tags: {
        nodes?: Array<{
          name: string
          uri: string
          slug: string
        }>
      }
    }
  }
}

function BreadcrumbStructuredData({ title, uri, category }) {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://www.liquidweb.com/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Blog",
            "item": "https://www.liquidweb.com/blog/"
          },{
            "@type": "ListItem",
            "position": 3,
            "name": "${category.name}",
            "item": "https://www.liquidweb.com${category.uri}"
          },{
            "@type": "ListItem",
            "position": 4,
            "name": "${title}",
            "item": "https://www.liquidweb.com${uri}"
          }]
        }`}
      </script>
    </Helmet>
  )
}

export const pageQuery = graphql`
  query GET_POST($id: String!) {
    post: wpPost(id: { eq: $id }) {
      title
      lwContent {
        isGutenberg
        content
      }
      slug
      uri
      date
      modified
      chatDepartment
      featuredImage {
        node {
          mediaItemUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      author {
        node {
          name
          id
          description
          uri
          avatar {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  width: 96
                  height: 96
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FIXED
                )
              }
            }
          }
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      tags {
        nodes {
          name
          uri
          slug
        }
      }
      seo {
        ...postSeoFields
      }
      ...ReusableBlockPostFragment
    }
  }
`

export default PostTemplate
