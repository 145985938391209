import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from '../Link'

function BlogSidebarAdWidget({ tags, className }) {
  const {
    dedicatedSidebarImage,
    eventsCalendarSidebarImage,
    vmwareSidebarImage,
    vpsSidebarImage,
  } = useStaticQuery(graphql`{
    dedicatedSidebarImage: file(relativePath: {eq: "blog/advert/dedicated-blog-sidebar.png"}) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 300
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    eventsCalendarSidebarImage: file(relativePath: {eq: "blog/advert/events-calendar-blog-sidebar.png"}) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 300
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    vmwareSidebarImage: file(relativePath: {eq: "blog/advert/vmware-blog-sidebar.png"}) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 300
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
    vpsSidebarImage: file(relativePath: {eq: "blog/advert/vps-blog-sidebar.png"}) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 300
          placeholder: TRACED_SVG
          layout: FIXED
        )
      }
    }
  }`);
  
  const images = [
    dedicatedSidebarImage,
    eventsCalendarSidebarImage,
    vmwareSidebarImage,
    vpsSidebarImage,
  ]

  const EVENTSCALENDAR = {
    tag: 'events-calendar',
    alt: 'The Events Calendar - try it for free.',
    link: 'https://theeventscalendar.com/products/wordpress-events-calendar/?utm_source=liquidweb&utm_medium=banner&utm_campaign=lw-blog-tryitfree',
    caption: 'A Liquid Web Family of Brands',
  }
  const DEDICATED = {
    tag: 'dedicated',
    alt: 'Dedicated Hosting Solutions',
    link: 'https://www.liquidweb.com/products/dedicated/?utm_source=liquidweb&utm_medium=display&utm_campaign=mh-dedi-top-dsp-blogdedi',
  }
  const VPS = {
    tag: 'vps',
    alt: 'Fully Managed VPS Hosting',
    link: 'https://www.liquidweb.com/products/vps/?utm_source=liquidweb&utm_medium=display&utm_campaign=mh-vps-top-dsp-blogvps',
  }
  const VMWARE = {
    tag: 'vmware',
    alt: 'VMware Hosting Solutions',
    link: 'https://www.liquidweb.com/products/private-cloud/?utm_source=liquidweb&utm_medium=display&utm_campaign=mh-vmw-top-dsp-blogpc',
  }

  /**
   * Object containing the tag name as key and the ad to be displayed as value
   * If no tag is specified the default ad will be EVENTSCALENDAR
   * const keywords = {tag.slug: EVENTSCALENDAR|DEDICATED|VPS|ADDONS|VMWARE}
   */
  const keywords = {
    'events-calendar': EVENTSCALENDAR,
    dedicated: DEDICATED,
    vps: VPS,
    vmware: VMWARE,
  }

  let key = EVENTSCALENDAR
  for (const index in tags) {
    if (keywords[tags[index].slug]) {
      key = keywords[tags[index].slug]
      break
    }
  }

  const image = images.find(item => item.name === `${key.tag}-blog-sidebar`);

  return (
    <div className={className}>
      <figure
        className="mx-auto"
        style={{
          marginInline: '0',
          marginBlockStart: '0',
          marginBlockEnd: '2.5rem',
        }}
      >
        <Link forceAnchor className="text-white hover:text-white" to={key.link}>
          {image && (
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt={key.alt}
              className="mx-auto cursor-pointer"
            />
          )}
        </Link>
        {key.caption && (
          <figcaption className="mt-2 text-sm text-center">
            {key.caption}
          </figcaption>
        )}
      </figure>
    </div>
  )
}

BlogSidebarAdWidget.defaultProps = {
  className: undefined,
}

BlogSidebarAdWidget.propTypes = {
  tags: PropTypes.array,
  className: PropTypes.string,
}

export default BlogSidebarAdWidget
