import React from 'react'
import PropTypes from 'prop-types'

import Facebook from '../../images/kb/facebook.inline.svg'
import Twitter from '../../images/kb/twitter.inline.svg'
import Linkedin from '../../images/kb/linkedin.inline.svg'
function ShareSocialIcons({ className, postTitle, postURL }) {
  function handleLink(event, name) {
    event.preventDefault()
    window.open(event.currentTarget.href, name, 'width=580,height=296')

  }
  const currentURL = encodeURI(postURL)
  const pageTitle = encodeURIComponent(postTitle)


  return (
    <div className={className}>
      <a
        className="inline-block mr-2"
        style={{ width: '25px' }}
        href={ `https://www.facebook.com/sharer/sharer.php?u=${ currentURL }` }
        onClick={event => handleLink(event, 'facebook-share')}
      >
        <Facebook />
      </a>
      <a
        className="inline-block mx-2"
        style={{ width: '25px' }}
        href={ `http://twitter.com/share?text=${ pageTitle }&url=${ currentURL }` }
        onClick={event => handleLink(event, 'twitter-share')}
      >
        <Twitter />
      </a>
      <a
        className="inline-block mx-2"
        style={{ width: '25px' }}
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${ currentURL }`}
        onClick={event => handleLink(event, 'linked-in-share')}
      >
        <Linkedin />
      </a>
    </div>
  )
}

ShareSocialIcons.propTypes = {
  className: PropTypes.string,
  postTitle: PropTypes.string.isRequired,
  postURL: PropTypes.string.isRequired,
}

export default ShareSocialIcons
